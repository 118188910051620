@if (buttonContainerTemplateRepository.templateData$ | async; as templateData) {
  <div
    [fxLayout]="templateData.activeLayout"
    [fxLayoutAlign]="templateData.activeLayoutAlign"
    [class.fixed-size]="templateData.isFixedSize"
    [class.full-size]="templateData.isFullSize"
  >
    <!-- Unlike a Button Container, a Button Template Container has template slots -->
    <ng-content></ng-content>
  </div>
}
