import { AsyncPipe } from '@angular/common';
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  HostBinding,
  Input,
  OnDestroy,
  QueryList,
} from '@angular/core';
import { MediaObserver } from '@ngbracket/ngx-layout';
import { FlexModule } from '@ngbracket/ngx-layout/flex';
import { Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ButtonComponent } from '../../components/button/button.component';
import { ButtonContainerTemplateLayouts, ButtonContainerTemplateSizing } from './button-container-template.model';
import { ButtonContainerTemplateRepository } from './button-container-template.repository';
import { ButtonContainerTemplateService } from './button-container-template.service';

@Component({
  selector: 'app-button-container-template',
  templateUrl: './button-container-template.component.html',
  styleUrls: ['../button-container/button-container.component.scss'],
  providers: [ButtonContainerTemplateService, ButtonContainerTemplateRepository],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [FlexModule, AsyncPipe],
})
export class ButtonContainerTemplateComponent implements OnDestroy, AfterContentInit {
  /**
   * This component exposes a slot for content projection. In order to tie this into the logic
   * of a button container, it is important to know how many buttons we have.
   *
   * It uses `ContentChildren` and not `ViewChildren` because it's projected.
   */
  @ContentChildren(ButtonComponent) buttonComponents: QueryList<ButtonComponent>;

  /**
   * Adds a Stacked Class to the host when a column mode is used
   */
  @HostBinding('class.isStacked') get isStacked() {
    if (this.isStackedEnabled) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Preferred Layout if there is a single button.
   */
  @Input() set preferredSingleLayout(value: ButtonContainerTemplateLayouts) {
    this.service.applySingleLayoutPreference(value);
  }

  /**
   * Preferred Layout if there is two buttons.
   */
  @Input() set preferredDualLayout(value: ButtonContainerTemplateLayouts) {
    this.service.applyDualLayoutPreference(value);
  }

  /**
   * Preferred Button sizing for Single Buttons
   */
  @Input() set preferredSingleButtonSizing(value: ButtonContainerTemplateSizing) {
    this.service.applySingleButtonSizingPreference(value);
  }

  /**
   * Hostbinding doesn't accept an observable so we need a property for stacking.
   */
  private isStackedEnabled = false;

  /**
   * Any active descriptions to be destroyed
   */
  private subscriptions = new Subscription();

  /**
   * Override Constructor
   */
  constructor(
    private readonly mediaObserver: MediaObserver,
    private readonly service: ButtonContainerTemplateService,
    public readonly buttonContainerTemplateRepository: ButtonContainerTemplateRepository,
  ) {}

  /**
   * Lifecycle
   */
  ngAfterContentInit() {
    // used to count the amount of content projected buttons
    this.service.applyButtonCount(this.buttonComponents.length);

    // listen out for when the button count changes
    const buttonCount = this.buttonComponents.changes.subscribe((value: QueryList<ButtonComponent>) => {
      this.service.applyButtonCount(value.length);
    });
    this.subscriptions.add(buttonCount);

    // setup the media buttonContainerTemplateRepository observer
    const media = this.mediaObserver
      .asObservable()
      .pipe(
        filter((changes) => changes.length > 0),
        map((changes) => changes[0]),
      )
      .subscribe((change) => {
        this.service.applyMediaChange(change);
      });
    this.subscriptions.add(media);

    // set the stacked value to the host binding
    const stacked = this.buttonContainerTemplateRepository.isStacked$.subscribe((val) => {
      this.isStackedEnabled = val;
    });
    this.subscriptions.add(stacked);
  }

  /**
   * Lifecycle
   */
  ngOnDestroy() {
    this.subscriptions?.unsubscribe();
  }
}
