import { Injectable } from '@angular/core';
import { MediaChange } from '@ngbracket/ngx-layout';
import { ButtonContainerTemplateLayouts, ButtonContainerTemplateSizing } from './button-container-template.model';
import { ButtonContainerTemplateRepository } from './button-container-template.repository';

/**
 * The Image Service is used by each instance of an {@link ImageComponent}.
 */
@Injectable()
export class ButtonContainerTemplateService {
  /**
   * Constructor
   */
  constructor(private readonly buttonContainerTemplateRepository: ButtonContainerTemplateRepository) {}

  /**
   * Preferred Layout if there is a single button.
   */
  applySingleLayoutPreference(value: ButtonContainerTemplateLayouts) {
    this.buttonContainerTemplateRepository.applySingleLayoutPreference(value);
  }

  /**
   * Preferred Layout if there is two buttons.
   */
  applyDualLayoutPreference(value: ButtonContainerTemplateLayouts) {
    this.buttonContainerTemplateRepository.applyDualLayoutPreference(value);
  }

  /**
   * Preferred Button sizing for Single Buttons
   */
  applySingleButtonSizingPreference(value: ButtonContainerTemplateSizing) {
    this.buttonContainerTemplateRepository.applySingleButtonSizingPreference(value);
  }

  /**
   * Occurs when there is a change in the amount of buttons within the slot.
   */
  applyButtonCount(count: number) {
    this.buttonContainerTemplateRepository.applyButtonCount(count);
  }

  /**
   * Occurs when a media change happens (responsiveness breakpoints)
   */
  applyMediaChange(change: MediaChange) {
    this.buttonContainerTemplateRepository.applyMediaChange(change);
  }
}
