import { Injectable, OnDestroy } from '@angular/core';
import { MediaChange } from '@ngbracket/ngx-layout';
import { createStore, select, withProps } from '@ngneat/elf';
import { uniqueId } from 'lodash-es';
import { map, of, switchMap } from 'rxjs';
import { ElfCombineQueries } from 'src/app/util/ElfCombineQueries';
import { ElfWrite } from 'src/app/util/ElfWrite';
import {
  ButtonContainerTemplateLayouts,
  ButtonContainerTemplateModel,
  ButtonContainerTemplateSizing,
} from './button-container-template.model';

/**
 * The Default State
 */
function initialState(): ButtonContainerTemplateModel {
  return {
    preferredSingleLayout: 'column',
    preferredDualLayout: 'column',
    preferredSingleButtonSizing: null,
    buttonCount: 0,
    activeLayout: 'column',
    activeLayoutAlign: 'start stretch',
    activeButtonSizing: null,
    mediaAlias: null,
    isStacked: false,
  };
}

/**
 * The Store used for a {@link ButtonContainerTemplateComponent}.
 *
 * It belongs to the {@link CoreModule}.
 */
@Injectable()
export class ButtonContainerTemplateRepository implements OnDestroy {
  /**
   * The store.
   */
  private store = createStore(
    {
      name: `button-container-template-${uniqueId()}`,
    },
    withProps<ButtonContainerTemplateModel>(initialState()),
  );

  ////////////////////////////////////////////////////////////////////
  // INITIALIZE
  ////////////////////////////////////////////////////////////////////

  /**
   * Apply layout preference for when there is a single button.
   *
   * The default is column.
   */
  applySingleLayoutPreference(layout: ButtonContainerTemplateLayouts = 'column') {
    // the layout must match an acceptable value
    if (layout !== 'row' && layout !== 'column' && layout !== 'row-reverse') {
      // or default to a column
      layout = 'column';
    }

    // update the store
    this.store.update(
      ElfWrite((state) => {
        state.preferredSingleLayout = layout;
      }),
    );

    // process the new state
    this.processState();
  }

  /**
   * Apply layout preference for when there is 2 buttons.
   *
   * The default is column.
   */
  applyDualLayoutPreference(layout: ButtonContainerTemplateLayouts = 'column') {
    // the layout must match an acceptable value
    if (layout !== 'row' && layout !== 'column' && layout !== 'row-reverse') {
      // or default to a column
      layout = 'column';
    }

    // update the store
    this.store.update(
      ElfWrite((state) => {
        state.preferredDualLayout = layout;
      }),
    );

    // process the new state
    this.processState();
  }

  /**
   * Apply button sizing preference for single button containers.
   *
   * The default is null.
   */
  applySingleButtonSizingPreference(buttonSizing: ButtonContainerTemplateSizing = null) {
    // the layout must match an acceptable value
    if (buttonSizing !== 'fullSize' && buttonSizing !== 'fixedSize') {
      // or default to null
      buttonSizing = null;
    }

    // update the store
    this.store.update(
      ElfWrite((state) => {
        state.preferredSingleButtonSizing = buttonSizing;
      }),
    );

    // process the new state
    this.processState();
  }

  /**
   * Occurs when a media change happens (responsiveness breakpoints)
   */
  applyMediaChange(change: MediaChange) {
    // update the store
    this.store.update(
      ElfWrite((state) => {
        state.mediaAlias = change.mqAlias;
      }),
    );

    // process the new state
    this.processState();
  }

  /**
   * Occurs when there is a change in the amount of buttons within the slot.
   */
  applyButtonCount(value: number) {
    // if the value is not a number, do nothing
    if (typeof value === 'undefined') {
      return;
    }

    // update the store
    this.store.update(
      ElfWrite((state) => {
        state.buttonCount = value;
      }),
    );

    // process the new state
    this.processState();
  }

  /**
   * Takes the current configuration and uses that information to process the state
   */
  private processState() {
    // gather the data from the store
    const {
      buttonCount,
      activeLayout,
      activeLayoutAlign,
      isStacked,
      activeButtonSizing,
      preferredSingleLayout,
      preferredSingleButtonSizing,
      mediaAlias,
      preferredDualLayout,
    } = this.store.getValue();

    // do nothing if the button count is zero as there is nothing to do
    if (buttonCount === 0) {
      return;
    }

    let newActiveLayout = activeLayout;
    let newActiveLayoutAlign = activeLayoutAlign;
    let newIsStacked = isStacked;
    let newActiveButtonSizing = activeButtonSizing;

    // process the logic based on button count
    switch (buttonCount) {
      // if there is more than 2 buttons, the only possible choice is a column.
      default:
        newActiveLayout = 'column';
        newActiveLayoutAlign = 'start stretch';
        newIsStacked = true;
        newActiveButtonSizing = null;
        break;

      // if there is one button, the preferred layout is used
      case 1:
        newIsStacked = false;
        newActiveLayout = preferredSingleLayout;
        newActiveButtonSizing = preferredSingleButtonSizing;

        // if the mediaAlias is xs then a column is forced
        if (newActiveLayout === 'column' || mediaAlias === 'xs') {
          newActiveLayout = 'column';
          newActiveLayoutAlign = 'start stretch';
        } else {
          newActiveLayoutAlign = 'space-between center';
        }
        break;

      case 2:
        newActiveButtonSizing = null;
        newActiveLayout = preferredDualLayout;
        newIsStacked = false;
        // if the mediaAlias is xs then a column is forced
        if (newActiveLayout === 'column' || mediaAlias === 'xs') {
          if (preferredDualLayout === 'row-reverse') {
            newActiveLayout = 'column-reverse';
          } else {
            newActiveLayout = 'column';
          }
          newIsStacked = true;
          newActiveLayoutAlign = 'start stretch';
        } else {
          newActiveLayoutAlign = 'space-between center';
        }
        break;
    }

    // update the store
    this.store.update(
      ElfWrite((state) => {
        state.activeLayout = newActiveLayout;
        state.activeLayoutAlign = newActiveLayoutAlign;
        state.isStacked = newIsStacked;
        state.activeButtonSizing = newActiveButtonSizing;
      }),
    );
  }

  /**
   * Lifecycle Hook
   */
  ngOnDestroy() {
    this.store?.destroy();
  }

  ////////////////////////////////////////////////////////////////////
  // QUERIES
  ////////////////////////////////////////////////////////////////////

  /**
   * The Active Layout
   */
  private _activeLayout$ = this.store.pipe(select((state) => state.activeLayout));

  /**
   * The Active Layout Align
   */
  private _activeLayoutAlign$ = this.store.pipe(select((state) => state.activeLayoutAlign));

  /**
   * The Active Button Sizing
   */
  private _activeButtonSizing$ = this.store.pipe(select((state) => state.activeButtonSizing));

  /**
   * Is Fixed Size?
   */
  private _isFixedSize$ = this._activeButtonSizing$.pipe(map((sizingMode) => sizingMode === 'fixedSize'));

  /**
   * Is Full Size?
   */
  private _isFullSize$ = this._activeButtonSizing$.pipe(map((sizingMode) => sizingMode === 'fullSize'));

  /**
   * The Template Data
   */
  templateData$ = ElfCombineQueries([this._activeLayout$, this._activeLayoutAlign$, this._isFixedSize$, this._isFullSize$]).pipe(
    switchMap(([activeLayout, activeLayoutAlign, isFixedSize, isFullSize]) => {
      return of({
        activeLayout,
        activeLayoutAlign,
        isFixedSize,
        isFullSize,
      });
    }),
  );

  /**
   * Is the layout stacked?
   */
  isStacked$ = this.store.pipe(select((state) => state.isStacked));
}
